import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import useAuthCheck from '../hooks/useAuthCheck';

const Admin = () => {
  const [formDataList, setFormDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  // Auth check
  useAuthCheck();
  const navigate = useNavigate();

  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const username = userData?.username;

  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL_ADMIN);
      if (response.data) {
        setFormDataList(response.data);
        toast.success('Data processed successfully.');
      } else {
        toast.error('Failed to fetch data. Please try again later.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to fetch data. Please try again later.');
    }
  };

  const exportCSV = () => {
    const csvData = formDataList.map(formData => ({
      Name: formData.name,
      Email: formData.email,
      'Mobile Number': formData.mobile,
      Address: formData.address,
      'Created At': formData.created_at,
    }));
    return csvData;
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new jsPDF(orientation, unit, size);

    const headers = [['Name', 'Email', 'Mobile Number', 'Address', 'Created At']];
    const dataRows = formDataList.map(formData => [
      formData.name,
      formData.email,
      formData.mobile,
      formData.address,
      formData.created_at,
    ]);

    doc.autoTable({
      head: headers,
      body: dataRows,
    });

    doc.save('formData.pdf');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginate = (items, pageNumber, itemsPerPage) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedFormDataList = paginate(formDataList, currentPage, itemsPerPage);
  const totalPages = Math.ceil(formDataList.length / itemsPerPage);

  return (
    <div className="container mx-auto mt-5 p-4">
      <Toaster />
      <div className="flex flex-col md:flex-row items-center justify-between w-full p-6 bg-white shadow-lg">
        <h1 className="text-2xl font-bold text-gray-800">Welcome, {username}!</h1>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            toast.success('You have successfully logged out!');
            sessionStorage.removeItem('loggedIn');
            sessionStorage.removeItem('userData');
            navigate('/');
          }}
        >
          Logout
        </button>
      </div>
      <h2 className="mb-4 text-2xl font-semibold text-center">Register Users</h2>
      <div className="mb-3 flex space-x-2 justify-center">
        <CSVLink
          data={exportCSV()}
          filename={'formData.csv'}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          target="_blank"
        >
          Export CSV
        </CSVLink>
        <Button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={exportPDF}
        >
          Export PDF
        </Button>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full bg-white shadow-md rounded my-6">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Mobile Number</th>
              <th className="py-2 px-4 border-b">Address</th>
              <th className="py-2 px-4 border-b">Created At</th>
            </tr>
          </thead>
          <tbody>
            {paginatedFormDataList.map((formData, index) => (
              <tr key={index} className="text-center">
                <td className="py-2 px-4 border-b">{formData.name}</td>
                <td className="py-2 px-4 border-b">{formData.email}</td>
                <td className="py-2 px-4 border-b">{formData.mobile}</td>
                <td className="py-2 px-4 border-b">{formData.address}</td>
                <td className="py-2 px-4 border-b">{formData.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Admin;
