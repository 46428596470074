import React, { FC } from "react";
import { Box, Divider, Grid, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
// import logo from './path-to-your-logo.png'; // make sure to replace this with the actual path to your logo

const Footer: FC = () => {
  return (
    <Box
      sx={{
        bottom: '0',
        width: '100%',
        backgroundColor: '#1a1a1a',
        color: '#f5f5f5',
        padding: '40px 0',
        textAlign: 'center',
      }}
    >
      <Divider sx={{ bgcolor: '#3e3e3e' }} />
      <Grid container direction="column" alignItems="center" spacing={2}>
        {/* <Grid item>
          <img src={logo} alt="Brand Logo" style={{ height: '50px', margin: '16px 0' }} />
        </Grid> */}
        <Grid item>
          <Typography color="inherit" variant="body2">
            © {new Date().getFullYear()} - Secured By Hashtago
            {/* <span><img src={logo} alt="Brand Logo" style={{ height: '50px', margin: '16px 0' }} /></span> */}
          </Typography>
        </Grid>
        <Grid item>
          <Link href="/privacy-policy" underline="hover" color="inherit" variant="body2" sx={{ mx: 1 }}>
            Privacy Policy
          </Link>
          <Link href="/terms-of-service" underline="hover" color="inherit" variant="body2" sx={{ mx: 1 }}>
            Terms of Service
          </Link>
        </Grid>
        {/* <Grid item>
          <IconButton aria-label="Facebook" color="inherit" href="https://www.facebook.com" target="_blank">
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="Twitter" color="inherit" href="https://www.twitter.com" target="_blank">
            <TwitterIcon />
          </IconButton>
          <IconButton aria-label="Instagram" color="inherit" href="https://www.instagram.com" target="_blank">
            <InstagramIcon />
          </IconButton>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Footer;
