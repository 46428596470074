// src/hooks/useAuthCheck.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";

const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      toast.error("You are not logged in. Redirecting to login page...");
      navigate("/");
    } else {
      toast.success("You have successfully logged into your account!");
    }
  }, [navigate]);
};

export default useAuthCheck;
