// src/firebaseConfig.js
const firebaseConfig = {
  apiKey: "AIzaSyCzN-SntTVbMvaqtG4Z3K7gsunwdyGFUYI",
  authDomain: "kedarnath-otp-a4fa4.firebaseapp.com",
  projectId: "kedarnath-otp-a4fa4",
  storageBucket: "kedarnath-otp-a4fa4.appspot.com",
  messagingSenderId: "830630498863",
  appId: "1:830630498863:web:6cb309aaa607cc59a86cda"
};

 export default firebaseConfig;








//VERSION 2.00

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

// export default firebaseConfig;

