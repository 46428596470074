
//v-1.00(original)

// import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import * as firebaseui from 'firebaseui';
// import 'firebaseui/dist/firebaseui.css';
// import { Toaster, toast } from 'react-hot-toast';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { TextField, Button, Box, Container, Typography, IconButton, InputAdornment } from '@mui/material';

// const PhoneAuth = (props) => {
//     const [form, setForm] = useState({
//         name: '',
//         email: '',
//         address: '',
//         mobile: '',
//         password: ''
//     });
//     const [mobileVerified, setMobileVerified] = useState(false);
//     const [showPhoneAuth, setShowPhoneAuth] = useState(true);
//     const [errors, setErrors] = useState({});
//     const [isFormValid, setIsFormValid] = useState(false);
//     const [signupSuccess, setSignupSuccess] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (showPhoneAuth) {
//             const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(props.auth);
//             ui.start('.phone-auth-container', {
//                 signInOptions: [
//                     {
//                         provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//                         recaptchaParameters: {
//                             type: 'image',
//                             size: 'normal',
//                             badge: 'bottomleft'
//                         },
//                         defaultCountry: 'IN',
//                         defaultNationalNumber: form.mobile,
//                         loginHint: form.mobile
//                     }
//                 ],
//                 callbacks: {
//                     signInSuccessWithAuthResult: (authResult) => {
//                         const phoneNumber = authResult.user.phoneNumber;
//                         setForm(prevForm => ({ ...prevForm, mobile: phoneNumber }));
//                         setMobileVerified(true);
//                         setShowPhoneAuth(false);
//                         toast.success('Mobile number verified successfully!');
//                         return false; // Prevent redirect
//                     },
//                     signInFailure: (error) => {
//                         toast.error('Mobile verification failed. Please try again.');
//                     }
//                 }
//             });
//         }
//     }, [showPhoneAuth, form.mobile, props.auth]);

//     useEffect(() => {
//         validateForm();
//     }, [form, mobileVerified]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setForm({ ...form, [name]: value });
//     };

//     const validateForm = () => {
//         let tempErrors = {};
//         tempErrors.name = form.name ? '' : 'Name is required';
//         tempErrors.email = form.email ? '' : 'Email is required';
//         tempErrors.address = form.address ? '' : 'Address is required';
//         tempErrors.password = form.password ? '' : 'Password is required';
        
//         const passwordPattern = /^(?=.*[0-9])(?=.*[A-Z]).{8,24}$/;
//         if (!passwordPattern.test(form.password)) {
//             tempErrors.password = 'Password must be 8-24 characters long and include at least one uppercase letter and one number';
//         }

//         setErrors(tempErrors);
//         setIsFormValid(Object.values(tempErrors).every(x => x === '') && mobileVerified);
//     };

//     const checkMobileExists = async (mobile) => {
//         try {
//             const response = await axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/mobilechecker.php', { mobile });
//             return response.data.exists;
//         } catch (error) {
//             toast.error('Failed to check mobile number. Please try again.');
//             return false;
//         }
//     };

//     const handleSignup = async () => {
//         if (isFormValid) {
//             const mobileExists = await checkMobileExists(form.mobile);
//             if (mobileExists) {
//                 toast.error('Mobile number already exists. Try with another Mobile number or Go for Login');
//                 setTimeout(() => {
//                     navigate('/'); // Redirect to login page
//                 }, 2000);
//                 return;
//             }

//             axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/signup.php', form)
//                 .then(response => {
//                     if (response.status === 200) {
//                         toast.success('Signup successful!');
//                         setSignupSuccess(true);
//                         setTimeout(() => {
//                             navigate('/PhoneLogin'); // Redirect to login page
//                         }, 2000);
//                     } else {
//                         toast.error('Signup failed. Please try again.');
//                     }
//                 })
//                 .catch(error => {
//                     toast.error('Signup failed. Please try again.');
//                 });

//             axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/reg.php', form)
//                 .then(response => {
//                     if (response.status === 200) {
//                         console.log('User details saved successfully');
//                     } else {
//                         console.error('Failed to save user details');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Failed to save user details', error);
//                 });
//         } else {
//             toast.error('Please fill in all required fields.');
//         }
//     };

//     const handleNumberChange = () => {
//         setShowPhoneAuth(true);
//         setMobileVerified(false);
//     };

//     const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//     if (signupSuccess) {
//         return (
//             <div className="min-h-screen bg-gray-900 flex items-center justify-center">
//                 <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
//                     <Typography variant="h4" component="h2" className="text-black mb-6 text-center">
//                         Signup Successful!
//                     </Typography>
//                     <Typography variant="body1" className="text-lg text-black text-center">
//                         You have successfully signed up. Redirecting to login page...
//                     </Typography>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gray-900 flex items-center justify-center relative">
//             <Toaster position="top-center" reverseOrder={false} />
//             <Container maxWidth="sm">
//                 <Box
//                     component="form"
//                     onSubmit={handleSignup}
//                     className="bg-white p-8 rounded-lg shadow-md space-y-6"
//                 >
//                     <Typography variant="h4" component="h2" className="text-gray-800 mb-6 text-center">
//                         SIGN UP
//                     </Typography>
//                     {!showPhoneAuth ? (
//                         <>
//                             <TextField
//                                 label="Name"
//                                 name="name"
//                                 value={form.name}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.name}
//                                 helperText={errors.name}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Email"
//                                 name="email"
//                                 value={form.email}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.email}
//                                 helperText={errors.email}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Address"
//                                 name="address"
//                                 value={form.address}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.address}
//                                 helperText={errors.address}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Password"
//                                 name="password"
//                                 type={showPassword ? 'text' : 'password'}
//                                 value={form.password}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.password}
//                                 helperText={errors.password}
//                                 variant="outlined"
//                                 className={`mb-6 ${errors.password ? '' : 'border-green-500'}`}
//                                 InputProps={{
//                                     endAdornment: (
//                                         <InputAdornment position="end">
//                                             <IconButton
//                                                 onClick={handleClickShowPassword}
//                                                 edge="end"
//                                             >
//                                                 {showPassword ? <Visibility /> : <VisibilityOff />}
//                                             </IconButton>
//                                         </InputAdornment>
//                                     )
//                                 }}
//                             />
//                             <Box className="relative">
//                                 <TextField
//                                     label="Mobile Number"
//                                     name="mobile"
//                                     value={form.mobile}
//                                     onChange={handleChange}
//                                     fullWidth
//                                     disabled={mobileVerified}
//                                     error={!!errors.mobile}
//                                     helperText={errors.mobile}
//                                     variant="outlined"
//                                     className={`mb-6 ${mobileVerified ? 'border-green-500' : ''}`}
//                                 />
//                                 {mobileVerified && (
//                                     <CheckCircleOutlineIcon className="absolute right-3 top-3 text-green-500" />
//                                 )}
//                             </Box>
//                             <Button
//                                 onClick={handleNumberChange}
//                                 variant="outlined"
//                                 color="primary"
//                                 startIcon={<EditIcon />}
//                                 className="mb-6"
//                             >
//                                 Change Number
//                             </Button>
//                             <Button
//                                 type="button"
//                                 onClick={handleSignup}
//                                 fullWidth
//                                 variant="contained"
//                                 color="primary"
//                                 className={`w-full py-2 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={!isFormValid}
//                             >
//                                 Signup
//                             </Button>
//                             <div className="text-center mt-4">
//                                 <Typography variant="body2" className="text-gray-800">
//                                     Already registered?{' '}
//                                     <Button
//                                         onClick={() => navigate('/')}
//                                         variant="text"
//                                         color="primary"
//                                     >
//                                         Login here
//                                     </Button>
//                                 </Typography>
//                             </div>
//                         </>
//                     ) : (
//                         <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50" style={{ backdropFilter: 'blur(10px)' }}>
//                             <div className="bg-white p-8 rounded-lg shadow-lg relative w-full max-w-md">
//                                 <div className="phone-auth-container"></div>
//                                 <div className="text-center mt-4">
//                                     <Typography variant="body2" className="text-gray-800">
//                                         Already registered?{' '}
//                                         <Button
//                                             onClick={() => navigate('/PhoneLogin')}
//                                             variant="text"
//                                             color="primary"
//                                         >
//                                             Login here
//                                         </Button>
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </Box>
//             </Container>
//             {/* Hanging Brand Sticker Tab */}
//             {/* <div className="absolute right-1/4 top-2 transform -translate-y-1/2 bg-blue-700 text-white px-2 py-1 rounded-lg shadow-md rotate-90 origin-top-left lg:right--10 lg:top-1/4 lg:-translate-y-1/2 lg:transform lg:origin-top-left">
//                 <span className="font-bold text-xs">Secured by Hashtago</span>
//             </div> */}
//         </div>
//     );
// };

// export default PhoneAuth;









//v-2.00
// import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import * as firebaseui from 'firebaseui';
// import 'firebaseui/dist/firebaseui.css';
// import { Toaster, toast } from 'react-hot-toast';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import EditIcon from '@mui/icons-material/Edit';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { TextField, Button, Box, Container, Typography, IconButton, InputAdornment } from '@mui/material';

// const PhoneAuth = (props) => {
//     const [form, setForm] = useState({
//         name: '',
//         email: '',
//         address: '',
//         mobile: '',
//         password: ''
//     });
//     const [mobileVerified, setMobileVerified] = useState(false);
//     const [showPhoneAuth, setShowPhoneAuth] = useState(true);
//     const [errors, setErrors] = useState({});
//     const [isFormValid, setIsFormValid] = useState(false);
//     const [signupSuccess, setSignupSuccess] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (showPhoneAuth) {
//             const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(props.auth);
//             ui.start('.phone-auth-container', {
//                 signInOptions: [
//                     {
//                         provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//                         recaptchaParameters: {
//                             type: 'image',
//                             size: 'invisible',
//                             badge: 'bottomleft'
//                         },
//                         defaultCountry: 'IN',
//                         defaultNationalNumber: form.mobile,
//                         loginHint: form.mobile
//                     }
//                 ],
//                 callbacks: {
//                     signInSuccessWithAuthResult: (authResult) => {
//                         const phoneNumber = authResult.user.phoneNumber;
//                         setForm(prevForm => ({ ...prevForm, mobile: phoneNumber }));
//                         setMobileVerified(true);
//                         setShowPhoneAuth(false);
//                         toast.success('Mobile number verified successfully!');
//                         return false; // Prevent redirect
//                     },
//                     signInFailure: (error) => {
//                         toast.error('Mobile verification failed. Please try again.');
//                     }
//                 }
//             });
//         }
//     }, [showPhoneAuth, form.mobile, props.auth]);

//     useEffect(() => {
//         validateForm();
//     }, [form, mobileVerified]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setForm({ ...form, [name]: value });
//     };

//     const validateForm = () => {
//         let tempErrors = {};
//         tempErrors.name = form.name ? '' : 'Name is required';
//         tempErrors.email = form.email ? '' : 'Email is required';
//         tempErrors.address = form.address ? '' : 'Address is required';
//         tempErrors.password = form.password ? '' : 'Password is required';
        
//         const passwordPattern = /^(?=.*[0-9])(?=.*[A-Z]).{8,24}$/;
//         if (!passwordPattern.test(form.password)) {
//             tempErrors.password = 'Password must be 8-24 characters long and include at least one uppercase letter and one number';
//         }

//         setErrors(tempErrors);
//         setIsFormValid(Object.values(tempErrors).every(x => x === '') && mobileVerified);
//     };

//     const checkMobileExists = async (mobile) => {
//         try {
//             const response = await axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/mobilechecker.php', { mobile });
//             return response.data.exists;
//         } catch (error) {
//             toast.error('Failed to check mobile number. Please try again.');
//             return false;
//         }
//     };

//     const handleSignup = async (e) => {
//         e.preventDefault();
//         if (isFormValid) {
//             const mobileExists = await checkMobileExists(form.mobile);
//             if (mobileExists) {
//                 toast.error('Mobile number already exists. Try with another Mobile number or Go for Login');
//                 setTimeout(() => {
//                     navigate('/'); // Redirect to login page
//                 }, 2000);
//                 return;
//             }

//             axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/signup.php', form)
//                 .then(response => {
//                     if (response.status === 200) {
//                         toast.success('Signup successful!');
//                         setSignupSuccess(true);
//                         setTimeout(() => {
//                             navigate('/'); // Redirect to login page
//                         }, 2000);
//                     } else {
//                         toast.error('Signup failed. Please try again.');
//                     }
//                 })
//                 .catch(error => {
//                     toast.error('Signup failed. Please try again.');
//                 });

//             axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/reg.php', form)
//                 .then(response => {
//                     if (response.status === 200) {
//                         console.log('User details saved successfully');
//                     } else {
//                         console.error('Failed to save user details');
//                     }
//                 })
//                 .catch(error => {
//                     console.error('Failed to save user details', error);
//                 });
//         } else {
//             toast.error('Please fill in all required fields.');
//         }
//     };

//     const handleNumberChange = () => {
//         setShowPhoneAuth(true);
//         setMobileVerified(false);
//     };

//     const handleClickShowPassword = () => setShowPassword((prev) => !prev);

//     if (signupSuccess) {
//         return (
//             <div className="min-h-screen bg-gray-900 flex items-center justify-center">
//                 <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
//                     <Typography variant="h4" component="h2" className="text-black mb-6 text-center">
//                         Signup Successful!
//                     </Typography>
//                     <Typography variant="body1" className="text-lg text-black text-center">
//                         You have successfully signed up. Redirecting to login page...
//                     </Typography>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gray-900 flex items-center justify-center relative">
//             <Toaster position="top-center" reverseOrder={false} />
//             <Container maxWidth="sm">
//                 <Box
//                     component="form"
//                     onSubmit={handleSignup}
//                     className="bg-white p-8 rounded-lg shadow-md space-y-6"
//                 >
//                     <Typography variant="h4" component="h2" className="text-gray-800 mb-6 text-center">
//                         SIGN UP
//                     </Typography>
//                     {!showPhoneAuth ? (
//                         <>
//                             <TextField
//                                 label="Name"
//                                 name="name"
//                                 value={form.name}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.name}
//                                 helperText={errors.name}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Email"
//                                 name="email"
//                                 value={form.email}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.email}
//                                 helperText={errors.email}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Address"
//                                 name="address"
//                                 value={form.address}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.address}
//                                 helperText={errors.address}
//                                 variant="outlined"
//                                 className="mb-6"
//                             />
//                             <TextField
//                                 label="Password"
//                                 name="password"
//                                 type={showPassword ? 'text' : 'password'}
//                                 value={form.password}
//                                 onChange={handleChange}
//                                 fullWidth
//                                 error={!!errors.password}
//                                 helperText={errors.password}
//                                 variant="outlined"
//                                 className={`mb-6 ${errors.password ? '' : 'border-green-500'}`}
//                                 InputProps={{
//                                     endAdornment: (
//                                         <InputAdornment position="end">
//                                             <IconButton
//                                                 onClick={handleClickShowPassword}
//                                                 edge="end"
//                                             >
//                                                 {showPassword ? <Visibility /> : <VisibilityOff />}
//                                             </IconButton>
//                                         </InputAdornment>
//                                     )
//                                 }}
//                             />
//                             <Box className="relative">
//                                 <TextField
//                                     label="Mobile Number"
//                                     name="mobile"
//                                     value={form.mobile}
//                                     onChange={handleChange}
//                                     fullWidth
//                                     disabled={mobileVerified}
//                                     error={!!errors.mobile}
//                                     helperText={errors.mobile}
//                                     variant="outlined"
//                                     className={`mb-6 ${mobileVerified ? 'border-green-500' : ''}`}
//                                 />
//                                 {mobileVerified && (
//                                     <CheckCircleOutlineIcon className="absolute right-3 top-3 text-green-500" />
//                                 )}
//                             </Box>
//                             {mobileVerified && (
//                                 <Button
//                                     onClick={handleNumberChange}
//                                     variant="outlined"
//                                     color="primary"
//                                     startIcon={<EditIcon />}
//                                     className="mb-6"
//                                 >
//                                     Change Number
//                                 </Button>
//                             )}
//                             <Button
//                                 type="submit"
//                                 fullWidth
//                                 variant="contained"
//                                 color="primary"
//                                 className={`w-full py-2 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
//                                 disabled={!isFormValid}
//                             >
//                                 Signup
//                             </Button>
//                             <div className="text-center mt-4">
//                                 <Typography variant="body2" className="text-gray-800">
//                                     Already registered?{' '}
//                                     <Button
//                                         onClick={() => navigate('/')}
//                                         variant="text"
//                                         color="primary"
//                                     >
//                                         Login here
//                                     </Button>
//                                 </Typography>
//                             </div>
//                         </>
//                     ) : (
//                         <div className=" fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50" style={{ backdropFilter: 'blur(10px)' }}>
//                             <div className="bg-white p-8 rounded-lg shadow-lg relative w-full max-w-md">
//                                 <div className="phone-auth-container"></div>
//                                 <div className="text-center mt-4">
//                                     <Typography variant="body2" className="text-gray-800">
//                                         Already registered?{' '}
//                                         <Button
//                                             onClick={() => navigate('/')}
//                                             variant="text"
//                                             color="primary"
//                                         >
//                                             Login here
//                                         </Button>
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </Box>
//             </Container>
//         </div>
//     );
// };

// export default PhoneAuth;



//v-3.00
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField, Button, Box, Container, Typography, IconButton, InputAdornment } from '@mui/material';

const PhoneAuth = (props) => {
    const [form, setForm] = useState({
        name: '',
        email: '',
        address: '',
        mobile: '',
        password: ''
    });
    const [mobileVerified, setMobileVerified] = useState(false);
    const [showPhoneAuth, setShowPhoneAuth] = useState(true);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (showPhoneAuth) {
            // Setup the RecaptchaVerifier
            if (!window.recaptchaVerifier) {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log('reCAPTCHA verified successfully');
                    },
                    'expired-callback': () => {
                        toast.error('reCAPTCHA expired. Please try again.');
                    }
                });
            }

            const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(props.auth);
            ui.start('.phone-auth-container', {
                signInOptions: [
                    {
                        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                        recaptchaParameters: {
                            size: 'invisible'
                        },
                        defaultCountry: 'IN',
                        defaultNationalNumber: form.mobile,
                        loginHint: form.mobile
                    }
                ],
                callbacks: {
                    signInSuccessWithAuthResult: (authResult) => {
                        const phoneNumber = authResult.user.phoneNumber;
                        setForm(prevForm => ({ ...prevForm, mobile: phoneNumber }));
                        setMobileVerified(true);
                        setShowPhoneAuth(false);
                        toast.success('Mobile number verified successfully!');
                        return false; // Prevent redirect
                    },
                    signInFailure: (error) => {
                        console.error('Mobile verification failed:', error);
                        toast.error('Mobile verification failed. Please try again.');
                    }
                }
            });
        }
    }, [showPhoneAuth, form.mobile, props.auth]);

    useEffect(() => {
        validateForm();
    }, [form, mobileVerified]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.name = form.name ? '' : 'Name is required';
        tempErrors.email = form.email ? '' : 'Email is required';
        tempErrors.address = form.address ? '' : 'Address is required';
        tempErrors.password = form.password ? '' : 'Password is required';
        
        const passwordPattern = /^(?=.*[0-9])(?=.*[A-Z]).{8,24}$/;
        if (!passwordPattern.test(form.password)) {
            tempErrors.password = 'Password must be 8-24 characters long and include at least one uppercase letter and one number';
        }

        setErrors(tempErrors);
        setIsFormValid(Object.values(tempErrors).every(x => x === '') && mobileVerified);
    };

    const checkMobileExists = async (mobile) => {
        try {
            const response = await axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/mobilechecker.php', { mobile });
            return response.data.exists;
        } catch (error) {
            toast.error('Failed to check mobile number. Please try again.');
            return false;
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (isFormValid) {
            const mobileExists = await checkMobileExists(form.mobile);
            if (mobileExists) {
                toast.error('Mobile number already exists. Try with another Mobile number or Go for Login');
                setTimeout(() => {
                    navigate('/'); // Redirect to login page
                }, 2000);
                return;
            }

            axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/signup.php', form)
                .then(response => {
                    if (response.status === 200) {
                        toast.success('Signup successful!');
                        setSignupSuccess(true);
                        setTimeout(() => {
                            navigate('/'); // Redirect to login page
                        }, 2000);
                    } else {
                        toast.error('Signup failed. Please try again.');
                    }
                })
                .catch(error => {
                    toast.error('Signup failed. Please try again.');
                });

            axios.post('https://admin.kedarnathconstruction.com/auth-API/api/signup/reg.php', form)
                .then(response => {
                    if (response.status === 200) {
                        console.log('User details saved successfully');
                    } else {
                        console.error('Failed to save user details');
                    }
                })
                .catch(error => {
                    console.error('Failed to save user details', error);
                });
        } else {
            toast.error('Please fill in all required fields.');
        }
    };

    const handleNumberChange = () => {
        setShowPhoneAuth(true);
        setMobileVerified(false);
    };

    const handleClickShowPassword = () => setShowPassword((prev) => !prev);

    if (signupSuccess) {
        return (
            <div className="min-h-screen bg-gray-900 flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                    <Typography variant="h4" component="h2" className="text-black mb-6 text-center">
                        Signup Successful!
                    </Typography>
                    <Typography variant="body1" className="text-lg text-black text-center">
                        You have successfully signed up. Redirecting to login page...
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center relative">
            <Toaster position="top-center" reverseOrder={false} />
            <Container maxWidth="sm">
                <Box
                    component="form"
                    onSubmit={handleSignup}
                    className="bg-white p-8 rounded-lg shadow-md space-y-6"
                >
                    <Typography variant="h4" component="h2" className="text-gray-800 mb-6 text-center">
                        SIGN UP
                    </Typography>
                    {!showPhoneAuth ? (
                        <>
                            <TextField
                                label="Name"
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.name}
                                helperText={errors.name}
                                variant="outlined"
                                className="mb-6"
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.email}
                                helperText={errors.email}
                                variant="outlined"
                                className="mb-6"
                            />
                            <TextField
                                label="Address"
                                name="address"
                                value={form.address}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.address}
                                helperText={errors.address}
                                variant="outlined"
                                className="mb-6"
                            />
                            <TextField
                                label="Password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={form.password}
                                onChange={handleChange}
                                fullWidth
                                error={!!errors.password}
                                helperText={errors.password}
                                variant="outlined"
                                className={`mb-6 ${errors.password ? '' : 'border-green-500'}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Box className="relative">
                                <TextField
                                    label="Mobile Number"
                                    name="mobile"
                                    value={form.mobile}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={mobileVerified}
                                    error={!!errors.mobile}
                                    helperText={errors.mobile}
                                    variant="outlined"
                                    className={`mb-6 ${mobileVerified ? 'border-green-500' : ''}`}
                                />
                                {mobileVerified && (
                                    <CheckCircleOutlineIcon className="absolute right-3 top-3 text-green-500" />
                                )}
                            </Box>
                            {mobileVerified && (
                                <Button
                                    onClick={handleNumberChange}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    className="mb-6"
                                >
                                    Change Mobile Number
                                </Button>
                            )}
                        </>
                    ) : (
                        <div className="phone-auth-container"></div>
                    )}
                    <div id="recaptcha-container"></div>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!isFormValid}
                    >
                        Sign Up
                    </Button>
                </Box>
            </Container>
        </div>
    );
};

export default PhoneAuth;











