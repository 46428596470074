






//VERSION 2.00
import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Button, IconButton, Container, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Toaster, toast } from "react-hot-toast";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://admin.kedarnathconstruction.com/auth-API/api/login/login.php", {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Successfully logged in!");
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userData", JSON.stringify(response.data.data));
          window.location.href = "/Enquiry-1";
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <Toaster />
      <Container maxWidth="sm">
        <Box
          component="form"
          onSubmit={handleSubmit}
          className="bg-gray-700 p-8 rounded-lg shadow-lg space-y-6"
        >
          <Typography variant="h4" component="h2" className="text-white mb-6 text-center">
            Sign in
          </Typography>
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            id="email"
            label="Email address"
            fullWidth
            className="mb-6"
            InputLabelProps={{ className: "text-white" }}
            InputProps={{
              className: "text-white",
            }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            id="password"
            label="Password"
            fullWidth
            className="mb-6"
            InputLabelProps={{ className: "text-white" }}
            InputProps={{
              className: "text-white",
              endAdornment: (
                <IconButton onClick={handleShowPassword} className="text-white">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button variant="contained" type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white">
            Login
          </Button>
          {/* <Typography variant="body2" className="text-white mt-4 text-center">
            Don't have an account? <a href="/PhoneAuth" className="text-blue-400">Sign up</a>
          </Typography> */}
        </Box>
      </Container>
      {/* Hanging Brand Sticker Tab */}
      {/* <div className="absolute -right-12 -top--5 transform -translate-y-1/2 bg-blue-700 text-white px-2 py-1 rounded-lg shadow-md rotate-90 origin-top-left">
                <span className="font-bold text-xs">Secured by Hashtago</span>
            </div> */}
    </div>
  );
}

export default Login;










